


.cta-button span{
  position: relative;
  z-index: 2;
}

.cta-button:after{
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  border-radius: 5px;
  background: #000;
  color: #fff;
  transition: all .35s;
}

.cta-button:hover{
  color: #fff;
}

.cta-button:hover:after{
  width: 100%;
}