@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Nunito Sans', 'Noto Kufi Arabic', sans-serif;
    font-weight: 400;
    /* background-color: #f8f5f5; */
    background-color: #fff;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Nunito Sans', 'Noto Kufi Arabic', sans-serif;
    font-weight: 700;
  }